<template>
  <div
    class="standardization-verification px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full">
        <TitleComponent
          class="py-8"
          title="Standardization and Verification"
          size="large"
        />

        <p class="font-bold">
          The
          <router-link style="color:#ef9a3d;" to="/charter"
            >charter</router-link
          >
          of the ET Users Group™ is to “systematically minimize the variability
          associated with energetic materials testing to enable consistent test
          data and interpretation of test results.” One of the ways to
          accomplish this is to verify that all of the critical test parameters
          are properly in place including (1) machine calibration and
          verification, (2) operational procedures, (3) sample preparation and
          application, and (4) reaction detection. Verification of an accurate
          test is (1) repeatable test data and (2) a successful Round Robin with
          multiple laboratories.
        </p>

        <div
          class="flex flex-wrap mt-8 mb-4 px-6 py-10"
          style="background-color:#f7f5f4;"
        >
          <div class="w-full md:w-1/2 pr-3">
            <h2
              class="mb-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              STANDARDIZATION
            </h2>

            <p
              style="color:rgb(127, 137, 145);line-height:32px;font-size:15px;"
            >
              Standardization of sensitivity and reactivity testing for
              in-process characterization and classification is the focus of the
              ET Users Group. Standardization procedures and protocols have been
              developed by the group. Implementation per the ET Users Group
              includes three steps:
            </p>

            <ul class="checklist pt-6" style="font-size:20px;font-size:15px;">
              <li class="fusion-li-item mb-4">
                <span class="icon-wrapper circle-no"
                  ><i
                    class="fusion-li-icon fa-check fas"
                    style="color:#f09a3e;"
                  ></i
                ></span>
                <div
                  class="fusion-li-item-content"
                  style="margin-left:36px;color:#7f8991;"
                >
                  Put in place standard procedures and protocols for repeatable
                  and consistent testing
                </div>
              </li>
              <li class="fusion-li-item mb-4">
                <span class="icon-wrapper circle-no"
                  ><i
                    class="fusion-li-icon fa-check fas"
                    style="color:#f09a3e;"
                  ></i
                ></span>
                <div
                  class="fusion-li-item-content"
                  style="margin-left:36px;color:#7f8991;"
                >
                  Complete a repeatability study where a sample is tested
                  repeatedly with the standardized procedure with the same setup
                  and key parameter values (outlined in may of the Round Robin
                  test procedures with links below). Results compared in a
                  statistically meaningful way with the
                  <a href="" style="color:#ef9a3d;">SRC method</a> or
                  equivalent;
                </div>
              </li>
              <li class="fusion-li-item mb-4">
                <span class="icon-wrapper circle-no"
                  ><i
                    class="fusion-li-icon fa-check fas"
                    style="color:#f09a3e;"
                  ></i
                ></span>
                <div
                  class="fusion-li-item-content"
                  style="margin-left:36px;color:#7f8991;"
                >
                  Complete testing per the applicable Round Robin procedure with
                  a statistical comparison of results to the ET Users Group
                  community average (e.g. with the
                  <a href="" style="color:#ef9a3d;">SRC method</a>).
                </div>
              </li>
            </ul>

            <p
              class="mt-6"
              style="color:rgb(127, 137, 145);line-height:32px;font-size:15px;"
            >
              The ET Users Group has expended significant effort to develop
              standardized procedures and test results with statistical
              comparisons. Multiple laboratories participated by following the
              above steps.
              <strong
                >Your lab can similarly complete testing in accordance with the
                groups efforts and statistically compare your answers to the ET
                Users Group result.</strong
              >
              Afterwards, please share your results with us.
            </p>
          </div>
          <div class="w-full md:w-1/2 pl-3">
            <h2
              class="mb-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              STANDARDS
            </h2>

            <div
              style="border-color:#e7e4e2;border-width:0px;background-color:#ffffff;"
              class="px-6 py-4 mb-4 fusion-panel panel-default fusion-toggle-no-divider fusion-toggle-boxed-mode"
              role="tabpanel"
            >
              <div class="panel-heading pl-6">
                <h4
                  class="panel-title toggle"
                  style="position:relative;"
                  data-fontsize="16"
                  data-lineheight="22"
                >
                  <a
                    @click="showContent = showContent === 1 ? false : 1"
                    :class="{ open: showContent === 1 }"
                    style="cursor:pointer;"
                    aria-expanded="false"
                    aria-selected="false"
                    aria-controls="811950d795ab93cb4"
                    role="tab"
                    data-toggle="collapse"
                  >
                    <span class="fusion-toggle-icon-wrapper" aria-hidden="true">
                      <i class="fa-fusion-box"></i>
                    </span>
                    <span
                      class="fusion-toggle-heading font-bold"
                      style="color:#ef9a3d;"
                      >In-Process Friction Test Standardization</span
                    >
                  </a>
                </h4>
              </div>
              <transition name="fade">
                <div
                  id="811950d795ab93cb4"
                  class="panel-collapse collapse"
                  v-if="showContent === 1"
                >
                  <div class="panel-body toggle-content fusion-clearfix pt-6">
                    <p class="mb-4" style="color:#7e8890;line-height:34px;">
                      The ABL Friction test is an in-process test as it provides
                      results in engineering units that can readily be compared
                      to in-process scenarios.&nbsp; Standardized procedures for
                      the verification of standardization (VoS) or Round Robin
                      testing are available. There are also standard procedures
                      on how to apply the surface finish as well as how to
                      determine a reaction.
                    </p>
                    <p class="mb-4" style="color:#7e8890;line-height:34px;">
                      The BAM Friction is the most common friction machine and
                      is frequently used nationally and internationally.
                      Unfortunately, the in-process application is limited due
                      to the use of ceramic pins and plates which are not used
                      in explosive processes. Nonetheless, standardization
                      efforts are detailed.
                    </p>
                    <div class="fusion-button-wrapper fusion-align-block mb-4">
                      <router-link
                        to="/standardization-and-verification/friction-standardization"
                        class="fusion-button button-flat fusion-button-default-size button-default button-1 fusion-button-default-span fusion-button-default-type"
                      >
                        <button
                          class="text-white font-bold py-2 px-4 rounded w-full btn"
                          style="background-color:#f09a3e;"
                        >
                          More Info for <u>ABL Friction</u>
                        </button>
                      </router-link>
                    </div>
                    <div class="fusion-button-wrapper fusion-align-block">
                      <router-link
                        to="/standardization-and-verification/bam-friction-standardization"
                        class="fusion-button button-flat fusion-button-default-size button-default button-1 fusion-button-default-span fusion-button-default-type"
                      >
                        <button
                          class="text-white font-bold py-2 px-4 rounded w-full btn"
                          style="background-color:#f09a3e;"
                        >
                          More Info for <u>BAM Friction</u>
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </transition>
            </div>

            <div
              style="border-color:#e7e4e2;border-width:0px;background-color:#ffffff;"
              class="px-6 py-4 mb-4 fusion-panel panel-default fusion-toggle-no-divider fusion-toggle-boxed-mode"
              role="tabpanel"
            >
              <div class="panel-heading pl-6">
                <h4
                  class="panel-title toggle"
                  style="position:relative;"
                  data-fontsize="16"
                  data-lineheight="22"
                >
                  <a
                    @click="showContent = showContent === 2 ? false : 2"
                    :class="{ open: showContent === 2 }"
                    style="cursor:pointer;"
                    aria-expanded="false"
                    aria-selected="false"
                    aria-controls="811950d795ab93cb4"
                    role="tab"
                    data-toggle="collapse"
                  >
                    <span class="fusion-toggle-icon-wrapper" aria-hidden="true">
                      <i class="fa-fusion-box"></i>
                    </span>
                    <span
                      class="fusion-toggle-heading font-bold"
                      style="color:#ef9a3d;"
                      >In-Process Impact Test Standardization</span
                    >
                  </a>
                </h4>
              </div>
              <transition name="fade">
                <div
                  id="811950d795ab93cb4"
                  class="panel-collapse collapse"
                  v-if="showContent === 2"
                >
                  <div class="panel-body toggle-content fusion-clearfix pt-6">
                    <p class="mb-4" style="color:#7e8890;line-height:34px;">
                      The MBOM Impact test is an in-process test as it provides
                      results in engineering units that can readily be compared
                      to in-process scenarios. Standardized procedures for the
                      verification of standardization (VoS) or Round Robin
                      testing are available. There are also standard procedures
                      on how to apply the surface finish as well as how to
                      determine a reaction.
                    </p>
                    <div class="fusion-button-wrapper fusion-align-block">
                      <router-link
                        to="/standardization-and-verification/impact-standardization"
                        class="fusion-button button-flat fusion-button-default-size button-default button-1 fusion-button-default-span fusion-button-default-type"
                      >
                        <button
                          class="text-white font-bold py-2 px-4 rounded w-full btn"
                          style="background-color:#f09a3e;"
                        >
                          More Info
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </transition>
            </div>

            <div
              style="border-color:#e7e4e2;border-width:0px;background-color:#ffffff;"
              class="px-6 py-4 mb-4 fusion-panel panel-default fusion-toggle-no-divider fusion-toggle-boxed-mode"
              role="tabpanel"
            >
              <div class="panel-heading pl-6">
                <h4
                  class="panel-title toggle"
                  style="position:relative;"
                  data-fontsize="16"
                  data-lineheight="22"
                >
                  <a
                    @click="showContent = showContent === 3 ? false : 3"
                    :class="{ open: showContent === 3 }"
                    style="cursor:pointer;"
                    aria-expanded="false"
                    aria-selected="false"
                    aria-controls="811950d795ab93cb4"
                    role="tab"
                    data-toggle="collapse"
                  >
                    <span class="fusion-toggle-icon-wrapper" aria-hidden="true">
                      <i class="fa-fusion-box"></i>
                    </span>
                    <span
                      class="fusion-toggle-heading font-bold"
                      style="color:#ef9a3d;"
                      >In-Process ESD Test Standardization</span
                    >
                  </a>
                </h4>
              </div>
              <transition name="fade">
                <div
                  id="811950d795ab93cb4"
                  class="panel-collapse collapse"
                  v-if="showContent === 3"
                >
                  <div class="panel-body toggle-content fusion-clearfix pt-6">
                    <p class="mb-4" style="color:#7e8890;line-height:34px;">
                      The ABL ESD test is an in-process test as it provides
                      results in engineering units that can readily be compared
                      to in-process scenarios. Standardized procedures for the
                      verification of standardization (VoS) or Round Robin
                      testing are available. There are also standard procedures
                      on how to apply the surface finish as well as how to
                      determine a reaction.
                    </p>
                    <div class="fusion-button-wrapper fusion-align-block">
                      <router-link
                        to="/standardization-and-verification/esd-standardization"
                        class="fusion-button button-flat fusion-button-default-size button-default button-1 fusion-button-default-span fusion-button-default-type"
                      >
                        <button
                          class="text-white font-bold py-2 px-4 rounded w-full btn"
                          style="background-color:#f09a3e;"
                        >
                          More Info
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </transition>
            </div>

            <div
              style="border-color:#e7e4e2;border-width:0px;background-color:#ffffff;"
              class="px-6 py-4 mb-4 fusion-panel panel-default fusion-toggle-no-divider fusion-toggle-boxed-mode"
              role="tabpanel"
            >
              <div class="panel-heading pl-6">
                <h4
                  class="panel-title toggle"
                  style="position:relative;"
                  data-fontsize="16"
                  data-lineheight="22"
                >
                  <a
                    @click="showContent = showContent === 4 ? false : 4"
                    :class="{ open: showContent === 4 }"
                    style="cursor:pointer;"
                    aria-expanded="false"
                    aria-selected="false"
                    aria-controls="811950d795ab93cb4"
                    role="tab"
                    data-toggle="collapse"
                  >
                    <span class="fusion-toggle-icon-wrapper" aria-hidden="true">
                      <i class="fa-fusion-box"></i>
                    </span>
                    <span
                      class="fusion-toggle-heading font-bold"
                      style="color:#ef9a3d;"
                      >In-Process Thermal Test Standardization</span
                    >
                  </a>
                </h4>
              </div>
              <transition name="fade">
                <div
                  id="811950d795ab93cb4"
                  class="panel-collapse collapse"
                  v-if="showContent === 4"
                >
                  <div class="panel-body toggle-content fusion-clearfix pt-6">
                    <p class="mb-4" style="color:#7e8890;line-height:34px;">
                      The
                      <router-link
                        style="color:rgb(239, 154, 61);"
                        to="/ip-thermal-test"
                        >Simulated Bulk Auto-Ignition Test</router-link
                      >
                      (SBAT) is used to test larger sample sizes to better
                      estimate the ignition temperature of in-process bulk
                      samples. The Differential Scanning Calorimeter (DSC) is
                      better suited for in-process conditions where the sample
                      size is small. Standardization efforts for the two tests
                      are detailed at the following link.
                    </p>
                    <div class="fusion-button-wrapper fusion-align-block mb-4">
                      <router-link
                        to="/standardization-and-verification/thermal-standardization"
                        class="fusion-button button-flat fusion-button-default-size button-default button-1 fusion-button-default-span fusion-button-default-type"
                      >
                        <button
                          class="text-white font-bold py-2 px-4 rounded w-full btn"
                          style="background-color:#f09a3e;"
                        >
                          More Info
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </transition>
            </div>

            <div
              style="border-color:#e7e4e2;border-width:0px;background-color:#ffffff;"
              class="px-6 py-4 mb-4 fusion-panel panel-default fusion-toggle-no-divider fusion-toggle-boxed-mode"
              role="tabpanel"
            >
              <div class="panel-heading pl-6">
                <h4
                  class="panel-title toggle"
                  style="position:relative;"
                  data-fontsize="16"
                  data-lineheight="22"
                >
                  <a
                    @click="showContent = showContent === 5 ? false : 5"
                    :class="{ open: showContent === 5 }"
                    style="cursor:pointer;"
                    aria-expanded="false"
                    aria-selected="false"
                    aria-controls="811950d795ab93cb4"
                    role="tab"
                    data-toggle="collapse"
                  >
                    <span class="fusion-toggle-icon-wrapper" aria-hidden="true">
                      <i class="fa-fusion-box"></i>
                    </span>
                    <span
                      class="fusion-toggle-heading font-bold"
                      style="color:#ef9a3d;"
                      >In-Process Koenen Test Standardization</span
                    >
                  </a>
                </h4>
              </div>
              <transition name="fade">
                <div
                  id="811950d795ab93cb4"
                  class="panel-collapse collapse"
                  v-if="showContent === 5"
                >
                  <div class="panel-body toggle-content fusion-clearfix pt-6">
                    <p class="mb-4" style="color:#7e8890;line-height:34px;">
                      The
                      <router-link
                        style="color:rgb(239, 154, 61);"
                        to="/ip-koenen-test-2"
                        >Koenen test</router-link
                      >
                      is both an In-Process and UN test to determine if a
                      substance has explosive properties. Additionally, the
                      Koenen test can be used to determine the burn rate and
                      associated required venting conditions in in-process and
                      storage scenarios. Standardization details are given.
                    </p>
                    <div class="fusion-button-wrapper fusion-align-block">
                      <router-link
                        to="/standardization-and-verification/koenen-standardization"
                        class="fusion-button button-flat fusion-button-default-size button-default button-1 fusion-button-default-span fusion-button-default-type"
                      >
                        <button
                          class="text-white font-bold py-2 px-4 rounded w-full btn"
                          style="background-color:#f09a3e;"
                        >
                          More Info
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </transition>
            </div>

            <div
              style="border-color:#e7e4e2;border-width:0px;background-color:#ffffff;"
              class="px-6 py-4 mb-4 fusion-panel panel-default fusion-toggle-no-divider fusion-toggle-boxed-mode"
              role="tabpanel"
            >
              <div class="panel-heading pl-6">
                <h4
                  class="panel-title toggle"
                  style="position:relative;"
                  data-fontsize="16"
                  data-lineheight="22"
                >
                  <a
                    @click="showContent = showContent === 6 ? false : 6"
                    :class="{ open: showContent === 6 }"
                    style="cursor:pointer;"
                    aria-expanded="false"
                    aria-selected="false"
                    aria-controls="811950d795ab93cb4"
                    role="tab"
                    data-toggle="collapse"
                  >
                    <span class="fusion-toggle-icon-wrapper" aria-hidden="true">
                      <i class="fa-fusion-box"></i>
                    </span>
                    <span
                      class="fusion-toggle-heading font-bold"
                      style="color:#ef9a3d;"
                      >Standardized Detonator Verification</span
                    >
                  </a>
                </h4>
              </div>
              <transition name="fade">
                <div
                  id="811950d795ab93cb4"
                  class="panel-collapse collapse"
                  v-if="showContent === 6"
                >
                  <div class="panel-body toggle-content fusion-clearfix pt-6">
                    <p class="mb-4" style="color:#7e8890;line-height:34px;">
                      The Standardized Detonator Verification Test is used to
                      determine the Detonator Number based on the Sand Crush
                      Test result. Standardized detonators are used in a number
                      of In-Process tests including the Cap Sensitivity test,
                      Gap tests, Shock sensitivity, and TNT Equivalency.
                    </p>
                    <div class="fusion-button-wrapper fusion-align-block">
                      <router-link
                        to="/standardization-and-verification/detonator-verification"
                        class="fusion-button button-flat fusion-button-default-size button-default button-1 fusion-button-default-span fusion-button-default-type"
                      >
                        <button
                          class="text-white font-bold py-2 px-4 rounded w-full btn"
                          style="background-color:#f09a3e;"
                        >
                          More Info
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </transition>
            </div>

            <!-- <router-link
              style="color:#ef9a3d;"
              to="/standardization-and-verification/round-robin-participation"
              >Interested in Participating? Fill out the form here</router-link
            > -->
            <p style="color:#ef9a3d;">
              <a href="https://forms.gle/HLT6wKgCvxYeD2xK8" target="_blank"
                >Interested in partcipating? Fill out the form here</a
              >.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";

export default {
  components: {
    TitleComponent
  },
  data: function() {
    return {
      showContent: false
    };
  }
};
</script>

<style lang="scss">
.standardization-verification {
  ul {
    list-style: none;
    li {
      position: relative;
    }
  }

  ul li:before {
    top: 5px;
    position: absolute;
    content: url("../assets/angle-right-solid.svg");
    filter: invert(70%) sepia(90%) saturate(601%) hue-rotate(326deg)
      brightness(94%) contrast(100%);
  }

  .fusion-button.button-1 {
    border-radius: 0px;
  }

  .panel-title a .fa-fusion-box:before {
    position: absolute;
    left: -2rem;
    top: 2px;
    content: url("../assets/plus-solid.svg");
    filter: invert(70%) sepia(90%) saturate(601%) hue-rotate(326deg)
      brightness(94%) contrast(100%);
  }

  .panel-title a.open .fa-fusion-box:before {
    content: url("../assets/minus-solid.svg");
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .btn:hover {
    background-color: #464d5f !important;
  }
}
</style>
